import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import styled from "styled-components"

import GlobalStyle from "../components/globalStyle"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import Footer from "../components/footer"
import SEO from "../components/seo"

const Content = styled.div`
  padding: 1rem 3rem;
  max-width: 1100px;
  margin: 0 auto;
  line-height: 32px;

  & p {
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  & h2 {
    font-size: 2rem;
    margin: 1rem 0;
  }
  & h3 {
    font-size: 1.8rem;
    margin: 1rem 0;
  }
`

export default ({ data }) => {
  const { frontmatter, body } = data.mdx
  return (
    <Layout>
      <SEO title={`Expandrr - ${frontmatter.title}`} />
      <PageHeader
        image={frontmatter.featuredImage.childImageSharp.original.src}
        text={frontmatter.title}
      ></PageHeader>
      <GlobalStyle />
      <Content>
        <MDXRenderer>{body}</MDXRenderer>
      </Content>
      <Footer></Footer>
    </Layout>
  )
}

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
        featuredImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`
